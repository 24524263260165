import { defineStore } from 'pinia'
import router from '../router'
import { Auth } from 'aws-amplify'

type AuthState = {
  user: null | any
  isLoggedIn: boolean
  userLoading: boolean
  userName: string
  userSession: string
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    isLoggedIn: false,
    userLoading: true,
    userName: '',
    userSession: ''
  }),
  actions: {
    async checkIfAuth() {
      this.setUserLoading(true)
      try {
        this.user = await Auth.currentUserInfo()
        this.setLoggedIn(!!this.user)
        this.setUserLoading(false)
      } catch (err) {
        this.setLoggedIn(false)
        this.setUserLoading(false)
      }
    },
    async retrieveActiveUser() {
      this.setUserLoading(true)
      try {
        this.user = await Auth.currentUserInfo()
        this.setLoggedIn(true)
        this.setUserLoading(false)
      } catch (err) {
        this.setLoggedIn(false)
        this.setUserLoading(false)
      }
    },
    async logout() {
      this.$reset()
      await Auth.signOut()
      window.location.assign('/')
      // router.go(0)
    },
    setLoggedIn(payload: boolean) {
      this.isLoggedIn = payload
    },
    setUserLoading(payload: boolean) {
      this.userLoading = payload
    }
  }
})
