<template>
  <div id="ms-top-bar" class="py-1 px-2 w-100">
    <Toolbar class="bg-transparent border-0">
      <template #start>
        <router-link to="/">
          <div class="flex gap-2 align-items-center">
            <SawetLogo :width="40" :height="40" />
            <span class="text-2xl font-semibold">SAWE-T</span>
          </div>
        </router-link>
      </template>

      <template #end>
        <div v-if="user" class="flex align-items-center gap-4">
          <router-link to="/dashboard/wellbeing">
            <Button label="Dashboard" class="px-2 py-1.5" text />
          </router-link>
          <i
            class="pi pi-align-right cursor-pointer"
            style="font-size: 1.5rem"
            @click="menuHandler"
          ></i>
        </div>
        <div v-else>
          <div class="flex gap-2 align-items-center">
            <router-link to="/auth">
              <Button label="Log in" severity="secondary" text />
            </router-link>
            <router-link to="/demonstration">
              <Button id="demo" label="Book a demo" />
            </router-link>
          </div>
        </div>
      </template>
    </Toolbar>
    <div v-if="menuOpen" class="card flex justify-content-center absolute right-0 z-20 border-1">
      <Menu :model="items" class="w-full md:w-15rem">
        <template #start>
          <span class="flex align-items-center justify-center gap-1 px-2 py-2">
            <SawetLogo />
            <span class="text-xl font-semibold">SAWE-T</span>
          </span>
        </template>
        <template #submenuheader="{ item }">
          <span class="text-primary font-bold">{{ item.label }}</span>
        </template>
        <template #item="{ item }">
          <a class="flex align-items-center">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <Badge v-if="item.badge" class="ml-auto" :value="item.badge" />
            <span
              v-if="item.shortcut"
              class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
            >
              {{ item.shortcut }}</span
            >
          </a>
        </template>
        <template #end>
          <div
            class="relative overflow-hidden w-full flex align-items-center p-2 pl-3 text-color border-noround"
          >
            <i class="pi pi-user mr-3" style="font-size: 1.5rem"></i>
            <span class="inline-flex flex-column">
              <span class="font-bold">{{ user != null ? user.username : '' }}</span>
              <span class="text-sm">Admin</span>
            </span>
          </div>
        </template>
      </Menu>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, type ComputedRef, onMounted, ref } from 'vue'
import Button from 'primevue/button'
import Toolbar from 'primevue/toolbar'
import Menu from 'primevue/menu'
import Badge from 'primevue/badge'
import { useAuthStore } from '@/store/auth.store'
import { useI18n } from 'vue-i18n'
import SawetLogo from '@/components/SawetLogo/SawetLogo.vue'
import type { MenuItem } from 'primevue/menuitem'
import router from '@/router'

const authStore = useAuthStore()
let menuOpen = ref<boolean>(false)

const { t } = useI18n()

let user = computed(() => {
  return authStore.user
})

function menuHandler() {
  menuOpen.value = !menuOpen.value
}

const items: ComputedRef<MenuItem[]> = computed(() => [
  {
    separator: true
  },
  {
    label: 'Help',
    icon: 'pi pi-question-circle',
    command: async () => {
      await router.push({
        name: 'HelpView'
      })
    }
  },
  {
    label: t('menu.user.logout'),
    icon: 'pi pi-sign-out',
    size: 'small',
    command: () => {
      authStore.logout()
    }
  },
  {
    separator: true
  }
])
</script>
<style scoped>
:deep(#demo.p-button) {
  background: linear-gradient(45deg, #00c6fb, #005bea);
  border: 0;
}
:deep(#demo.p-button:hover) {
  background: linear-gradient(135deg, #00c6fb, #005bea);
}
:deep(.p-menuitem-content) {
  cursor: pointer;
  padding: 16px 24px;
}
</style>
