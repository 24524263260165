import { createRouter, createWebHistory } from 'vue-router'
import AuthLayout from '@/features/auth/layout/AuthLayout.vue'
import { useAuthenticator } from '@aws-amplify/ui-vue'
import LandingLayout from '@/features/landing/layout/LandingLayout.vue'
import SurveyLayout from '@/features/survey/layout/SurveyLayout.vue'
import SurveyListLayout from '@/features/survey/layout/SurveyListLayout.vue'
import HelpLayout from '@/features/help/layout/HelpLayout/HelpLayout.vue'
import IframeDashboardLayout from '@/features/dashboard/layout/IframeDashboardLayout/IframeDashboardLayout.vue'

export type RouteMeta = {
  layout: typeof AuthLayout | typeof IframeDashboardLayout
  requiresAuth: boolean
  hideDuringAuth?: boolean
  requiresPermissions: any[]
  group?: string
  icon?: string
  key: string
  labelKey?: string
  showInMenu?: boolean
  breadcrumbDepth?: number
  breadcrumbTree?: string
}

export const navigationGroups = {
  dashboard: {
    key: 'dashboard',
    labelKey: 'menu.main.dashboard',
    icon: '',
    index: 0
  },
  surveys: {
    key: 'surveys',
    labelKey: 'menu.main.surveys',
    icon: '',
    index: 1
  },
  landing: {
    key: 'landing',
    labelKey: 'menu.main.landing',
    icon: '',
    index: 1
  }
} as const

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'LandingView',
      meta: {
        layout: LandingLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: false,
        key: 'landing'
      },
      component: () => import('@/features/landing/views/LandingView/LandingView.vue')
    },
    {
      path: '/surveys',
      name: 'SurveysListView',
      meta: {
        layout: SurveyListLayout,
        requiresAuth: true,
        requiresPermissions: [],
        group: navigationGroups.surveys.key,
        icon: '',
        key: 'SurveyListView',
        labelKey: 'menu.main.surveys',
        showInMenu: true,
        breadcrumbDepth: 1,
        breadcrumbTree: 'SurveyListView'
      },
      component: () => import('@/features/survey/views/SurveyListView/SurveyListView.vue')
    },
    {
      path: '/survey/:id',
      name: 'SurveyView',
      meta: {
        layout: SurveyLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: false,
        key: 'survey'
      },
      component: () => import('@/features/survey/views/SurveyView/SurveyView.vue')
    },
    {
      path: '/survey/confidentiality',
      name: 'SurveyConfidentialityView',
      meta: {
        layout: SurveyLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: false,
        key: 'surveyConfidentiality'
      },
      component: () =>
        import('@/features/survey/views/SurveyConfidentialityView/SurveyConfidentialityView.vue')
    },
    {
      path: '/auth',
      name: 'AuthView',
      meta: {
        layout: AuthLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: true,
        key: 'auth'
      },
      component: () => import('@/features/auth/views/AuthView/AuthView.vue')
    },
    {
      path: '/dashboard/:id',
      name: 'DashboardView',
      meta: {
        layout: IframeDashboardLayout,
        requiresAuth: true,
        requiresPermissions: [],
        group: navigationGroups.dashboard.key,
        icon: '',
        key: 'dashboardView',
        labelKey: 'menu.main.dashboard',
        showInMenu: true,
        breadcrumbDepth: 1,
        breadcrumbTree: 'dashboardView'
      },
      component: () => import('@/features/dashboard/views/DashboardView/DashboardView.vue')
    },
    {
      path: '/help',
      name: 'HelpView',
      meta: {
        layout: HelpLayout,
        requiresAuth: true,
        requiresPermissions: [],
        group: navigationGroups.landing.key,
        icon: '',
        key: 'helpView',
        labelKey: 'menu.main.help',
        showInMenu: true,
        breadcrumbDepth: 1,
        breadcrumbTree: 'helpView'
      },
      component: () => import('@/features/help/views/HelpView/HelpView.vue')
    },
    {
      path: '/careers',
      name: 'CareersView',
      meta: {
        layout: LandingLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: true,
        key: 'careers'
      },
      component: () => import('@/features/landing/views/CareersView/CareersView.vue')
    },
    {
      path: '/privacy',
      name: 'PrivacyView',
      meta: {
        layout: LandingLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: true,
        key: 'privacy'
      },
      component: () => import('@/features/landing/views/PrivacyView/PrivacyView.vue')
    },
    {
      path: '/demonstration',
      name: 'BookDemoView',
      meta: {
        layout: LandingLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: false,
        key: 'demonstration'
      },
      component: () => import('@/features/landing/views/BookDemoView/BookDemoView.vue')
    },
    {
      path: '/notfound',
      name: 'NotFound',
      meta: {
        layout: LandingLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: false,
        key: 'privacy'
      },
      component: () => import('@/features/unexpected/views/NotFound/NotFound.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      meta: {
        layout: LandingLayout,
        requiresAuth: false,
        requiresPermissions: [],
        hideDuringAuth: false,
        key: 'privacy'
      },
      component: () => import('@/features/unexpected/views/NotFound/NotFound.vue')
    }
  ]
})

router.beforeResolve((to, _, next) => {
  const auth = useAuthenticator()
  const meta = to.meta as RouteMeta
  if (meta.requiresAuth && auth.authStatus !== 'authenticated') {
    next('/auth')
    return
  }
  // else if (meta.hideDuringAuth && auth.authStatus === 'authenticated') {
  //   next('/dashboard/wellbeing')
  //   return
  // }
  next()
})

export default router
