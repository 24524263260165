import './assets/styles.scss'
import 'primeflex/primeflex.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

import { i18n } from './composables/useI18n'

// Amplify
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import { useAuthStore } from '@/store/auth.store'

Amplify.configure(awsExports)
const piniaStore = createPinia()

const app = createApp(App).use(piniaStore)

const authStore = useAuthStore()
authStore.checkIfAuth().finally(() => {
  app
    .use(i18n)
    .use(router)
    .use(PrimeVue)
    .use(ToastService)
    .use(ConfirmationService)
    .use(VueClipboard)
    .directive('tooltip', Tooltip)
    .mount('#app')
})
