import { type LanguageLocale } from '@/model/language-locale'
import { nextTick, ref } from 'vue'
import { createI18n } from 'vue-i18n'

export const LS_LOCALE_KEY = 'ms-locale'

export const SUPPORT_LOCALES: LanguageLocale[] = ['en']
export const DEFAULT_LOCALE: LanguageLocale = 'en'

export const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  legacy: false,
  globalInjection: true
})

export const useI18n = (initialLocale?: LanguageLocale) => {
  const localeRef = ref<LanguageLocale>(initialLocale || DEFAULT_LOCALE)

  const setI18nLanguage = (locale: LanguageLocale) => {
    i18n.global.locale.value = locale

    localStorage.setItem(LS_LOCALE_KEY, locale)

    document.querySelector('html')?.setAttribute('lang', locale)
  }

  const loadLocaleMessages = async (locale: LanguageLocale) => {
    const messages = await import(`../locales/${locale}`)

    i18n.global.setLocaleMessage(locale, messages.default)

    return nextTick()
  }

  const switchLocale = async (locale: LanguageLocale) => {
    await loadLocaleMessages(locale)
    setI18nLanguage(locale)
    localeRef.value = locale
  }

  return {
    switchLocale,
    locale: localeRef
  }
}
