<template>
  <component :is="route.meta.layout">
    <router-view :key="$route.fullPath"></router-view>
  </component>
  <Toast position="top-right" />
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue'
import { DEFAULT_LOCALE, useI18n } from '@/composables/useI18n'
import Toast from 'primevue/toast'
import { useRoute } from 'vue-router'

const { switchLocale } = useI18n()
const route = useRoute()

onBeforeMount(async () => {
  await switchLocale(DEFAULT_LOCALE)
})
</script>

<style scoped></style>
