<template>
  <div id="ms-top-bar" class="py-2 w-full">
    <Toolbar class="bg-transparent border-0">
      <template #start>
        <div class="flex align-items-center gap-2">
          <router-link to="/">
            <div class="flex gap-2 align-items-center">
              <SawetLogo :width="40" :height="40" />
              <span class="text-2xl font-semibold">SAWE-T</span>
            </div>
          </router-link>
          <router-link to="/dashboard/safety" class="ml-4">
            <Button label="Safety" text plain class="font-semibold text-gray-700" />
          </router-link>
          <router-link to="/dashboard/wellbeing" class="ml-4">
            <Button label="Wellbeing" text plain class="font-semibold text-gray-700" />
          </router-link>
          <!--          <router-link to="/dashboard/client" class="ml-4">-->
          <!--            <Button label="Client" text plain class="font-semibold text-gray-700" />-->
          <!--          </router-link>-->
          <router-link to="/surveys" class="ml-4">
            <Button label="Surveys" text plain class="font-semibold text-gray-700" />
          </router-link>
        </div>
      </template>

      <template #end>
        <div v-if="user">
          <i
            class="pi pi-align-right cursor-pointer"
            style="font-size: 1.5rem"
            @click="menuHandler"
          ></i>
        </div>
        <div v-else>
          <div class="flex gap-2 align-items-center">
            <router-link to="/auth">
              <Button label="Log in" severity="secondary" text />
            </router-link>
            <router-link to="/demonstration">
              <Button id="demo" label="Book a demo" />
            </router-link>
          </div>
        </div>
      </template>
    </Toolbar>
    <div v-if="menuOpen" class="card flex justify-content-center absolute right-0 z-20 border-1">
      <Menu :model="items" class="w-full md:w-15rem">
        <template #start>
          <span class="flex align-items-center justify-center gap-1 px-2 py-2">
            <SawetLogo />
            <span class="text-xl font-semibold">SAWE-T</span>
          </span>
        </template>
        <template #submenuheader="{ item }">
          <span class="text-primary font-bold">{{ item.label }}</span>
        </template>
        <template #item="{ item }">
          <a class="flex align-items-center">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <Badge v-if="item.badge" class="ml-auto" :value="item.badge" />
            <span
              v-if="item.shortcut"
              class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
            >
              {{ item.shortcut }}</span
            >
          </a>
        </template>
        <template #end>
          <div
            class="relative overflow-hidden w-full flex align-items-center p-2 pl-3 text-color border-noround"
          >
            <i class="pi pi-user mr-3" style="font-size: 1.5rem"></i>
            <span class="inline-flex flex-column">
              <span class="font-bold">{{ user != null ? user.username : '' }}</span>
              <span class="text-sm">Admin</span>
            </span>
          </div>
        </template>
      </Menu>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Toolbar from 'primevue/toolbar'
import Menu from 'primevue/menu'
import Badge from 'primevue/badge'
import { computed, type ComputedRef, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type MenuItem } from 'primevue/menuitem'
import { Auth } from 'aws-amplify'
import Button from 'primevue/button'
import SawetLogo from '@/components/SawetLogo/SawetLogo.vue'
import router from '@/router'
import { useAuthStore } from '@/store/auth.store'

const { t } = useI18n()

let user = ref(null)
let menuOpen = ref(false)
const authStore = useAuthStore()

const sideBarVisible = ref<boolean>(false)

onMounted(async () => {
  user.value = await Auth.currentUserInfo()
})

function menuHandler() {
  menuOpen.value = !menuOpen.value
}

const items: ComputedRef<MenuItem[]> = computed(() => [
  {
    separator: true
  },
  {
    label: 'Help',
    icon: 'pi pi-question-circle',
    command: async () => {
      await router.push({
        name: 'HelpView'
      })
    }
  },
  {
    label: t('menu.user.logout'),
    icon: 'pi pi-sign-out',
    // disabled: logoutLoading.value,
    size: 'small',
    command: () => {
      authStore.logout()
    }
  },
  {
    separator: true
  }
])
</script>
<style scoped>
:deep(.p-menuitem-content) {
  cursor: pointer;
  padding: 16px 24px;
}
</style>
